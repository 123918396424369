import React, { useState, useEffect } from 'react';
import './NavBar.css';
import { ReactComponent as EtyLogo } from './etyLogo.svg'; // Adjust the path if necessary
import { ReactComponent as ProductsIcon } from './ProductsIcon.svg'; // Adjust the path if necessary
import { ReactComponent as SupportIcon } from './SupportIcon.svg'; 

function NavBar() {
    const [selectedButton, setSelectedButton] = useState(null);
    const [language, setLanguage] = useState('en'); // Language state

    const handleButtonClick = (buttonName) => {
        setSelectedButton(buttonName);
    };

    const toggleLanguage = () => {
        setLanguage(language === 'en' ? 'ru' : 'en');
    };


    useEffect(() => {
        const browserLanguage = navigator.language || navigator.userLanguage;


        if (browserLanguage.toLowerCase().includes('ru')) {
            setLanguage('ru');
        } else {
            setLanguage('en');
        }
    }, []);

    return (
        <div>
            <nav className="navbar">
                <a className="navbar-left" href='/'>
                    <div className='logoCon'>
                        <EtyLogo className="logo" />
                    </div>
                    <span className="navbar-brand">eternity</span>
                </a>
                <div className="navbar-links">
                    <button
                        className={`navbar-button ${selectedButton === 'support' ? 'selected' : ''}`}
                        onClick={() => handleButtonClick('support')}
                    >
                        <SupportIcon className="button-icon" />
                        <span>{language === 'en' ? 'Support' : 'Поддержка'}</span>
                    </button>
                    <button
                        className={`navbar-button ${selectedButton === 'products' ? 'selected' : ''}`}
                        onClick={() => handleButtonClick('products')}
                    >
                        <ProductsIcon className="button-icon" />
                        <span>{language === 'en' ? 'Products' : 'Продукты'}</span>
                    </button>
                </div>
            </nav>

            <a className='head-login-button' href='/login'>
                {language === 'en' ? 'Log in' : 'Войти'}
            </a>

           
        </div>
    );
}

export default NavBar;

import axios from 'axios';

const API_BASE_URL = 'https://auth.ety.one/auth';
const PATMENTS_API_BASE_URL = 'https://checkout.ety.one';

const ApiService = {
  register: async (email, password, username, token) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/register`, {
        email,
        password,
        username,
        token
      });
      return response.data; // Handle success response
    } catch (error) {
      throw error.response.data; // Handle error response
    }
  },

  login: async (username, password) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/login`, {
        username,
        password,
      });
      return response.data; // Access and refresh tokens
    } catch (error) {
      throw error.response.data; // Handle error response
    }
  },

  refresh: async (refreshToken) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/refresh`, {
        refreshToken,
      });
      return response.data; // New access token
    } catch (error) {
      throw error.response.data; // Handle error response
    }
  },

  getSubscriptions: async (token) => {
    try {
      console.log(`${PATMENTS_API_BASE_URL}/getSubscriptions`);



      const response = await axios.get(`${PATMENTS_API_BASE_URL}/getSubscriptions`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error(error); // Log the full error to troubleshoot
      throw error.response ? error.response.data : error.message; // Handle error response
    }
  },

  changeSubscriptionStatus: async (token, subscriptionId, status) => {
    try {
      const response = await axios.post(
        `${PATMENTS_API_BASE_URL}/subscription/changeSubscriptionStatus`,
        {
          subscriptionId,
          status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add Bearer token here
          },
        }
      );
      return response.data; // Handle success response
    } catch (error) {
      console.error(error); // Log the full error to troubleshoot
      throw error.response ? error.response.data : error.message; // Handle error response
    }
  },
  

  getPaymentMethods: async (token) => {
    try {
      const response = await axios.get(`${PATMENTS_API_BASE_URL}/getPaymentMethods`, {
        headers: {
          Authorization: `Bearer ${token}`, // Add Bearer token here
        }
      });
      console.log(JSON.stringify(response.data))
      return response.data; // New access token
    } catch (error) {
      throw error.response.data; // Handle error response
    }
  }

};

export default ApiService;
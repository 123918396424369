import React from 'react';
import './ProductCard.css';

function ProductCard({ title, description, imgSrc, link }) {
    const handleDownloadClick = () => {
        window.location.href = link;
    };

    return (
        <div className="product-card">
            <div style={{display: "flex"}}>
                <img src={imgSrc} alt={title} className="update-card-img" />
                <div className="desc-con">
                    <h3>{title}</h3>
                    <p>{description}</p>
                </div>
            </div>

            <button className="download-button" onClick={handleDownloadClick}>
                Open
            </button>
        </div>
    );
}

export default ProductCard;

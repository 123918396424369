import React from 'react';
import './UpdateCard.css';

function UpdateCard({ title, description, imgSrc, link }) {
    return (
        <div className="update-card">
            <img src={imgSrc} alt={title} className="update-card-img" />
            <div className="update-card-content">
                <h3>{title}</h3>
                <p>{description}</p>
                <a href={link} className="open-link">Open</a>
            </div>
        </div>
    );
}

export default UpdateCard;

import React, { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode'; // Ensure correct import
import { useNavigate } from 'react-router-dom';
import './AccountPage.css';
import { ReactComponent as AccountIcon } from './userIcon.svg';
import { ReactComponent as CardIcon } from './cardIcon.svg';
import { ReactComponent as SubIcon } from './subIcon.svg';
import { ReactComponent as EtyLogo } from './etyLogo.svg';
import { ReactComponent as Dots } from './dotsIcon.svg';
import ApiService from './ApiService';

const AccountPage = () => {
    const [userData, setUserData] = useState(null);
    const [subscriptions, setSubscriptions] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [language, setLanguage] = useState('en');
    const [selectedTab, setSelectedTab] = useState('accountDetails');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
        } else {
            try {
                const decoded = jwtDecode(token);
                setUserData(decoded);
                fetchSubscriptions(token);
                fetchPaymentMethods(token);
            } catch (err) {
                console.error('Error decoding token:', err);
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }, [navigate]);

    const fetchSubscriptions = async (token) => {
        try {
            const response = await ApiService.getSubscriptions(token);
            setSubscriptions(response);
        } catch (error) {
            console.error('Error fetching subscriptions:', error);
        }
    };

    const fetchPaymentMethods = async (token) => {
        try {
            const response = await ApiService.getPaymentMethods(token);
            setPaymentMethods(response);
        } catch (error) {
            console.error('Error fetching payment methods:', error);
        }
    };

    const toggleLanguage = () => {
        setLanguage(language === 'en' ? 'ru' : 'en');
    };

    useEffect(() => {
        const browserLanguage = navigator.language || navigator.userLanguage;
        setLanguage(browserLanguage.toLowerCase().includes('ru') ? 'ru' : 'en');
    }, []);

    // New: Open the modal and set the selected subscription for status change
    const handleOpenModal = (subscription) => {
        setSelectedSubscription(subscription);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedSubscription(null);
    };

    const handleChangeSubscriptionStatus = async () => {
        const token = localStorage.getItem('token');
        if (!token || !selectedSubscription) return;

        try {
            await ApiService.changeSubscriptionStatus(
                token,
                selectedSubscription.id,
                selectedSubscription.status === 'ACTIVE' ? 'CANCELLED' : 'ACTIVE'
            );
            fetchSubscriptions(token); // Refresh the subscriptions after changing the status
        } catch (error) {
            console.error('Error changing subscription status:', error);
        } finally {
            handleCloseModal();
        }
    };

    const translations = {
        en: {
            accountInfo: 'Account Settings',
            username: 'Username',
            email: 'Email',
            userId: 'User ID',
            subscriptions: 'Subscriptions',
            noSubscriptions: 'No subscriptions available',
            loading: 'Loading...',
            switchToRussian: 'Switch to Russian',
            switchToEnglish: 'Switch to English',
            developmentBanner: 'The personal account is under development. To cancel your subscription, please contact cancel@ety.one or service support.',
            accountDetails: 'Account Details',
            paymentMethods: 'Payment Methods',
            subscriptionsTab: 'Subscriptions',
            expiryDate: 'Expiry Date',
            provider: 'Provider',
            changeStatus: 'Change Status',
            status: 'Status',
            confirmChange: 'Do you want to change the subscription status?',
            confirm: 'Confirm',
            cancel: 'Cancel',
            statusCancelled: 'Cancelled',
            statusActive: 'Active',
            confirmChange: 'Do you want to change the subscription status?',
            confirmCancel: 'Do you want to cancel the subscription?',
            confirmActivate: 'Do you want to activate the subscription?',
        },
        ru: {
            accountInfo: 'Настройки аккаунта',
            username: 'Имя пользователя',
            email: 'Электронная почта',
            userId: 'ID пользователя',
            subscriptions: 'Подписки',
            noSubscriptions: 'Подписок нет',
            loading: 'Загрузка...',
            switchToRussian: 'Переключиться на русский',
            switchToEnglish: 'Переключиться на английский',
            developmentBanner: 'Личный кабинет находится в стадии разработки. Для отмены подписки обратитесь на почту cancel@ety.one или в поддержку сервиса.',
            accountDetails: 'Данные аккаунта',
            paymentMethods: 'Способы оплаты',
            subscriptionsTab: 'Подписки',
            expiryDate: 'Срок действия',
            provider: 'Поставщик',
            changeStatus: 'Изменить статус',
            status: 'Статус',
            confirmChange: 'Вы хотите изменить статус подписки?',
            confirm: 'Подтвердить',
            cancel: 'Отмена',
            statusCancelled: 'Будет отменена',
            statusActive: 'Активна',
            confirmChange: 'Вы хотите изменить статус подписки?',
            confirmCancel: 'Вы хотите отменить подписку?',
            confirmActivate: 'Вы хотите активировать подписку?',
        }
    };

    if (!userData) {
        return <p>{translations[language].loading}</p>;
    }

    const renderTabContent = () => {
        switch (selectedTab) {
            case 'accountDetails':
                return (
                    <div className="account-info">
                        <h3>{translations[language].accountDetails}</h3>
                        <p><strong>{translations[language].username}:</strong> {userData.sub}</p>
                        <p><strong>{translations[language].email}:</strong> {userData.email}</p>
                        <p><strong>{translations[language].userId}:</strong> {userData.etyId}</p>
                    </div>
                );
            case 'paymentMethods':
                return (
                    <div>
                        <h3>{translations[language].paymentMethods}</h3>
                        {paymentMethods.length > 0 ? (
                            <ul className="payment-methods-list">
                                {paymentMethods.map((method, index) => (
                                    <li key={index} className="payment-card">
                                        <div className="card-left">
                                            <CardIcon className="card-icon" />
                                            <span className="card-last-four">**{method.cardLastFour}</span>
                                        </div>
                                        <div className="card-right">
                                            <div className="expiry-date">
                                                {translations[language].expiryDate}: {method.expiryDate}
                                            </div>
                                            <div className="provider">
                                                {translations[language].provider}: {method.type}
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>{translations[language].noSubscriptions}</p>
                        )}
                    </div>
                );
            case 'subscriptions':
                return (
                    <div className="subscriptions">
                        <h3>{translations[language].subscriptions}</h3>
                        {subscriptions.length > 0 ? (
                            <ul className="subscriptions-list">
                                {subscriptions.map((subscription, index) => (
                                    <li key={index}>
                                        {subscription.subscriptionPlan} ({subscription.status == "ACTIVE" ? translations[language].statusActive : translations[language].statusCancelled})
                                        <Dots className="dots" onClick={() => handleOpenModal(subscription)}>
                                            {translations[language].changeStatus}
                                        </Dots>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>{translations[language].noSubscriptions}</p>
                        )}
                        {/* <div className="banner">
                            {translations[language].developmentBanner}
                        </div> */}
                    </div>
                );
            // Other cases...
        }
    };

    return (
        <div className="acc-main">
            <div className="acc-head">
                <div className="head-hor">
                    <a className="logoCon" href="/">
                        <EtyLogo className="logo" />
                    </a>
                    <h2>etyID</h2>
                    <h2 className="h2-gray">{translations[language].accountInfo}</h2>
                </div>

                <div className="nav-buttons">
                    <button
                        onClick={() => setSelectedTab('accountDetails')}
                        className={selectedTab === 'accountDetails' ? 'selected' : ''}
                    >
                        <AccountIcon />
                        {translations[language].accountDetails}
                    </button>
                    <button
                        onClick={() => setSelectedTab('paymentMethods')}
                        className={selectedTab === 'paymentMethods' ? 'selected' : ''}
                    >
                        <CardIcon />
                        {translations[language].paymentMethods}
                    </button>
                    <button
                        onClick={() => setSelectedTab('subscriptions')}
                        className={selectedTab === 'subscriptions' ? 'selected' : ''}
                    >
                        <SubIcon />
                        {translations[language].subscriptionsTab}
                    </button>
                </div>
            </div>

            <div className="account-container">
                <div>{renderTabContent()}</div>
            </div>

            {/* Modal */}
            {isModalOpen && selectedSubscription && (
                <div className="modal">
                    <div className="modal-content">
                        <h3>
                            {selectedSubscription.status === 'ACTIVE'
                                ? translations[language].confirmCancel
                                : translations[language].confirmActivate}
                        </h3>
                        <button onClick={handleChangeSubscriptionStatus}>
                            {translations[language].confirm}
                        </button>
                        <button onClick={handleCloseModal}>
                            {translations[language].cancel}
                        </button>
                    </div>
                </div>
            )}


            <div className="footer">
                <a>eternity 2024</a>
                <span />
                <a className="footer-link" href="https://documents.ety.one/%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9/">
                    {language === 'en' ? 'Public Offer' : 'Публичная оферта'}
                </a>
                <a className="footer-link" href="https://documents.ety.one/%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9/">
                    {language === 'en' ? 'Personal Data Processing' : 'Обработка персональных данных'}
                </a>
                <a className="footer-link" href="https://documents.ety.one/%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9/subscription-agreement/">
                    {language === 'en' ? 'Cancel Subscription' : 'Отмена подписки'}
                </a>
                <button onClick={toggleLanguage}>
                    {language === 'en' ? 'Русский язык' : 'English language'}
                </button>
            </div>
        </div>
    );
};

export default AccountPage;

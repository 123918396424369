import React from 'react';
import NavBar from './NavBar';
import UpdateCard from './UpdateCard';
import ProductCard from './ProductCard';
import './App.css';
import WelcomeImage from './Welcome.png';
import DiraLogo from './diraIcon.svg';
import etyVpnLogo from './etyVpnLogo.svg';
import LoginPage from './LoginPage';
import HomePage from './HomePage';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import PublicOffer from './PublicOffer';
import SignUpPage from './SignUpPage';
import LoginSuccess from './LoginSuccess';
import AccountPage from './AccountPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/account" element={<AccountPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<SignUpPage />} />
        <Route path="/publicoffer" element={<PublicOffer />} />
        <Route path="/login-success" element={<LoginSuccess />} />
        <Route path="/" element={<HomePage/>} />
        {/* Add more routes here as needed */}
      </Routes>
    </Router>
  );
}

export default App;

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './LoginPage.css';
import { ReactComponent as EtyLogo } from './etyLogo.svg';
import { ReactComponent as SignIn } from './signIn.svg';
import { ReactComponent as SignUp } from './signUp.svg';
import ApiService from './ApiService';
import { Turnstile } from '@marsidev/react-turnstile';

const SignUpPage = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const [language, setLanguage] = useState('en'); // State to keep track of the selected language
    const [isChecked, setIsChecked] = useState(false); // Checkbox state
    const [token, setToken] = useState(null); // CAPTCHA token state
    const turnstileRef = useRef(null); // Reference to the Turnstile component
    const [stage, setStage] = useState(1); // State to manage form stages

    const navigate = useNavigate();
    const location = useLocation(); // Access location object to check URL params

    useEffect(() => {
        const browserLanguage = navigator.language || navigator.userLanguage;

        if (browserLanguage.toLowerCase().includes('ru')) {
            setLanguage('ru');
        } else {
            setLanguage('en');
        }
    }, []);

    const handleNextStep = () => {
        setError('');

        // Validation for Stage 1 (Email and Username)
        if (!email || !username) {
            setError(language === 'en' ? 'Email and Username are required' : 'Требуются электронная почта и имя пользователя');
            return;
        }

        if (!email.includes("@")) {
            setError(language === 'en' ? 'Email is not correct' : 'Введите настоящую почту');
            return;
        }

        // Proceed to the next stage if the first stage is valid
        setStage(2);
    };

    const handleSignUp = async () => {
        setError('');
        const params = new URLSearchParams(location.search);
       
        // Check if CAPTCHA token is present
        if (!token) {
            setError(language === 'en' ? 'Please complete the CAPTCHA challenge' : 'Пожалуйста, пройдите проверку CAPTCHA');
            return;
        }

        // Check if terms and conditions are agreed
        if (!isChecked) {
            setError(language === 'en' ? 'You must agree to the terms and conditions' : 'Вы должны согласиться с условиями и положениями');
            return;
        }

        // Check if passwords match
        if (password !== confirmPassword) {
            setError(language === 'en' ? 'Passwords do not match' : 'Пароли не совпадают');
            resetCaptcha(); // Reset CAPTCHA if an error occurs
            return;
        }

        try {
            const data = await ApiService.register(email, password, username, token);

            if (data === "success") {
                const params = new URLSearchParams(location.search);
                const referer = params.get('referer');
                
                if (referer === 'etycloud') {
                    window.open('https://cloud.ety.one/login', "_self");
                   
                } else {
                    navigate('/login-success');
                }
            }
        } catch (errorResponse) {
            setError(handleErrorResponse(errorResponse));
            setStage(1); // Reset to Stage 1 on error
        } finally {
            resetCaptcha(); // Always reset CAPTCHA after the API call
        }
    };

    const resetCaptcha = () => {
        // Reset the CAPTCHA widget if the reference exists
        if (turnstileRef.current) {
            turnstileRef.current.reset(); // Reset Turnstile widget
            setToken(null); // Reset token state
        }
    };

    const handleErrorResponse = (errorResponse) => {
        const errors = {
            en: {
                USERNAME_FORMAT_ERROR: 'Invalid username format.',
                PASSWORD_UNSECURE_ERROR: 'Password is not secure enough.',
                ACCOUNT_EXISTS: 'An account with email or nickname already exists.',
                REGION_DISALLOW_ERROR: 'Sign up is not allowed in your region.',
                EMAIL_FORMAT_ERROR: 'Invalid email format.',
                UNKNOWN_ERROR: 'An unknown error occurred. Please try again.',
                BOT_PROTECTION: 'Process bot protection',
            },
            ru: {
                USERNAME_FORMAT_ERROR: 'Неверный формат имени пользователя.',
                PASSWORD_UNSECURE_ERROR: 'Пароль недостаточно безопасен.',
                ACCOUNT_EXISTS: 'Аккаунт с этим email или именем пользователя уже существует.',
                REGION_DISALLOW_ERROR: 'Регистрация запрещена в вашем регионе.',
                EMAIL_FORMAT_ERROR: 'Неверный формат электронной почты.',
                UNKNOWN_ERROR: 'Произошла неизвестная ошибка. Пожалуйста, попробуйте еще раз.',
                BOT_PROTECTION: 'Пройдите проверку на бота',
            },
        };

        return errors[language][errorResponse] || errors[language].UNKNOWN_ERROR;
    };

    const toggleLanguage = () => {
        setLanguage(language === 'en' ? 'ru' : 'en');
    };

    return (
        <div className="login-container">
            <div className="login-card">
                <div className="login-header">
                    <div className='logoCon2'>
                        <EtyLogo className="logo2" />
                    </div>
                    <h2>{language === 'en' ? 'Create an account' : 'Создать аккаунт'}</h2>
                    <p>{language === 'en' ? 'Join our community' : 'Присоединяйтесь к нашему сообществу'}</p>
                </div>

                <div className="login-form">
                    {error && <p className="error-message">{error}</p>}

                    {/* Stage 1: Collect Email and Username */}
                    {stage === 1 && (
                        <>
                            <input
                                type="text"
                                placeholder={language === 'en' ? 'Username' : 'Имя пользователя'}
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            <input
                                type="email"
                                placeholder={language === 'en' ? 'Email' : 'Электронная почта'}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <button className="sign-in-btn" onClick={handleNextStep}>
                                <SignIn /> {language === 'en' ? 'Next' : 'Далее'}
                            </button>
                        </>
                    )}

                    {/* Stage 2: Collect Password, Confirm Password, and Agree to Terms */}
                    {stage === 2 && (
                        <>
                            <div className="password-field">
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder={language === 'en' ? 'Password' : 'Пароль'}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <input
                                    type="password"
                                    placeholder={language === 'en' ? 'Confirm Password' : 'Подтвердите пароль'}
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                                <button
                                    type="button"
                                    className="password-toggle"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? (language === 'en' ? 'Hide' : 'Скрыть') : (language === 'en' ? 'Show' : 'Показать')}
                                </button>
                            </div>

                            {/* Turnstile component with ref */}
                            <Turnstile
                                siteKey='0x4AAAAAAAxFq9ykoismNRKm'
                                onSuccess={setToken}
                                ref={turnstileRef} // Pass the reference to Turnstile
                            />

                            <div className="checkbox-container">
                                <input
                                    type="checkbox"
                                    id="terms-checkbox"
                                    checked={isChecked}
                                    onChange={(e) => setIsChecked(e.target.checked)}
                                />
                                <label htmlFor="terms-checkbox">
                                    {language === 'en' ? (
                                        <>
                                            I agree with the{' '}
                                            <a href="https://documents.ety.one/English" target="_blank" rel="noopener noreferrer">
                                                Privacy Policy and other documents
                                            </a>
                                        </>
                                    ) : (
                                        <>
                                            Я согласен(-на) с{' '}
                                            <a href="https://documents.ety.one/Русский" target="_blank" rel="noopener noreferrer">
                                                Политикой обработки данных и другими документами
                                            </a>
                                        </>
                                    )}
                                </label>
                            </div>

                            <button className="sign-in-btn" onClick={handleSignUp}>
                                <SignIn /> {language === 'en' ? 'Sign up' : 'Зарегистрироваться'}
                            </button>
                        </>
                    )}

                    <button className="sign-up-btn" onClick={() => navigate('/login')}>
                        <SignUp /> {language === 'en' ? 'Sign in' : 'Войти'}
                    </button>
                    <button className="language-toggle" onClick={toggleLanguage}>
                        {language === 'en' ? 'Русский язык' : 'English language'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SignUpPage;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css';
import { ReactComponent as EtyLogo } from './etyLogo.svg';
import { ReactComponent as SignIn } from './signIn.svg';
import { ReactComponent as SignUp } from './signUp.svg';
import ApiService from './ApiService';

const LoginSuccess = () => {

    const [language, setLanguage] = useState('en'); // Language state
    const navigate = useNavigate(); // Initialize useNavigate





    const toggleLanguage = () => {
        navigate('/login');
    };

    useEffect(() => {
        const browserLanguage = navigator.language || navigator.userLanguage;

      
        if (browserLanguage.toLowerCase().includes('ru')) {
            setLanguage('ru');
        } else {
            setLanguage('en');
        }
    }, []);

    return (
        <div className="login-container">
            <div className="login-card">
                <div className="login-header">
                    <div className="logoCon2">
                        <EtyLogo className="logo2" />
                    </div>
                    <h2>{language === 'en' ? 'Your account has been created' : 'Ваш аккаунт создан!'}</h2>
                    <p>{language === 'en' ? 'Now sign in to your account' : 'Теперь войдите в свой аккаунт'}</p>
                </div>

                <button className="language-toggle" onClick={toggleLanguage}>
                    {language === 'en' ? 'Log in' : 'Войти'}
                </button>
            </div>
        </div>
    );
};

export default LoginSuccess;
